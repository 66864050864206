import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Smallstep makes running your own private CA and managing certificates for internal services easy.  Open source `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` also supports OAuth for provisioning X.509 certificates for user authentication.  We make it easy for developers to obtain a certificate via single sign-on using your existing identity provider (Google, Okta, Active Directory, ...). Issued certificates are signed by the CA and trusted by all your workloads and services so you can use mTLS everywhere. `}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
    Any mention of "certificates" in this document refers to X.509 certificates.
  </div>
    </Alert>
    <h2>{`About this tutorial`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to obtain an X.509 certificate for user authentication via single sign-on using Google credentials.`}</li>
      <li parentName="ul">{`Examples include copy/paste code blocks and specific commands for G-Suite.`}</li>
      <li parentName="ul">{`When complete, you will have connected your identity provider and issued X.509 certificates for user authentication.`}</li>
      <li parentName="ul">{`Estimated effort: Reading time ~3 mins, Lab time ~10 to 60 mins. `}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">in GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open Source -`}</strong>{` This tutorial assumes you have initialized and started up a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`instance using the steps in `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://smallstep.com/certificate-manager"
          }}>{`Smallstep Certificate Manager`}</a>{` -`}</strong>{` Please visit the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/oidc"
        }}>{`Certs for Humans documentation`}</a>{` instead of using this tutorial to connect your identity provider to Certificate Manager.`}</li>
    </ul>
    <h2>{`Personal certificates via OAuth OpenID Connect`}</h2>
    <p>{`User identities are usually already managed by your existing G-Suite, Okta, Salesforce, or Microsoft Azure Active Directory `}<em parentName="p">{`identity provider`}</em>{`.
`}<em parentName="p">{`IDPs`}</em>{` leverage a single database of user accounts to provide single sign on login to a wide array of applications and services.
The `}<a parentName="p" {...{
        "href": "https://openid.net/connect/faq/"
      }}>{`OpenID Connect`}</a>{` protocol is commonly used to facilitate the exchange between the application, user, and IDP.
You can leverage OpenID Connect to authenticate with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to make issuance of personal certificates simple for your whole team.`}</p>
    <p>{`Here's how to obtain a user certificate using an OAuth token from G-Suite.`}</p>
    <h3>{`1. Add an OpenID Connect provisioner for your G-Suite`}</h3>
    <p>{`Add a `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#gcp"
      }}>{`G-suite provisioner`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`step ca provisioner add Google --type oidc \\
    --client-id 650445034027-jsjdrkiskeq9ke99ud2rqkst82ft8uch.apps.googleusercontent.com \\
    --client-secret 6Q7lGMua_Oox4nA92QBXYypT \\
    --configuration-endpoint https://accounts.google.com/.well-known/openid-configuration \\
    --domain smallstep.com --domain gmail.com
`}</code></pre>
    <h3>{`2. Reload the CA`}</h3>
    <p>{`You'll need to restart or SIGHUP `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` in order to pick up the configuration changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`kill -SIGHUP $(ps aux | grep step-ca | grep -v grep | awk '{print $2}')
`}</code></pre>
    <h3>{`3. Get a personal certificate using your G-Suite account`}</h3>
    <p>{`Be sure to specify your G-Suite email address (the command's first argument) and select the "Google" provisioner from the dropdown:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca certificate davey.oauth.jones@gmail.com personal.crt personal.key

Use the arrow keys to navigate: ↓ ↑ → ←
What provisioner key do you want to use?
    pDpbCsI8Thvci7EqyJJY7AoUIadufTeZQnAcBCwGuHE (admin)
  ▸ 650445034027-jsjdrkiskeq9ke99ud2rqkst82ft8uch.apps.googleusercontent.com (Google)
`}</code></pre>
    <p>{`Unlike regular JWK-based provisioners no password prompt will come up in the terminal.
Instead expect the default browser to open the G-Suite login screen.
After successfully logging-in the CA will issue a certificate for the respective user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect --short personal.crt
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 1445...0545]
  Subject:     113900523962383213156
               davey.oauth.jones@gmail.com
  Issuer:      Local CA Intermediate CA
  Provisioner: Google [ID: 6504....com]
  Valid from:  2019-03-20T18:17:55Z
          to:  2019-03-21T18:17:55Z
`}</code></pre>
    <h3>{`4. Test out your new personal X.509 Certificate`}</h3>
    <p>{`Let's bring up an example server configured to accept connections over mTLS.`}</p>
    <p>{`Below is some python code we'll use for our example server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import BaseHTTPServer, ssl
class H(BaseHTTPServer.BaseHTTPRequestHandler):
    def do_GET(self):
        self.send_response(200); self.send_header('content-type', 'text/html; charset=utf-8'); self.end_headers()
        san = self.connection.getpeercert().get('subjectAltName')[0][1]
        self.wfile.write(b'\\n\\xf0\\x9f\\x91\\x8b Hello '+san+'! Welcome to mTLS \\xf0\\x9f\\x94\\x92\\xe2\\x9c\\x85\\n\\n')
httpd = BaseHTTPServer.HTTPServer(('', 8443), H)
httpd.socket = ssl.wrap_socket (httpd.socket, server_side=True, keyfile="localhost.key", certfile="localhost.crt",
                                cert_reqs=ssl.CERT_REQUIRED, ca_certs="root_ca.crt")
httpd.serve_forever()
`}</code></pre>
    <p>{`Before running the server, let's create a server X.509 certificate and key pair:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca localhost localhost.crt localhost.key
`}</code></pre>
    <p>{`Now let's run our Python example server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`python server.py
`}</code></pre>
    <p>{`Finally we're ready to to test our personal certificate using `}<inlineCode parentName="p">{`curl`}</inlineCode>{`.
All we need to do is provide a root certificate for client-side server
authentication and our personal certificate and private key to pass the
server-side client authentication.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`curl --cacert $(step path)/certs/root_ca.crt --cert personal.crt --key personal.key https://localhost:8443
`}</code></pre>
    <p>{`Success!
Using OpenID Connect to make personal X.509 certificates for your teams is an
easy and powerful way to unlock strong identity for everybody, everywhere.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      